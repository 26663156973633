var searchKeys = [{
  key: "uid",
  placeholder: 'UID',
  input: true,
  label: '请输入UID'
}, {
  key: "nickname",
  placeholder: '请输入用户昵称',
  input: true,
  label: '用户昵称'
}, {
  key: "email",
  placeholder: '请输入邮箱',
  input: true,
  label: '邮箱'
}, {
  key: "searchtype",
  placeholder: '请选择时间',
  group: true,
  label: '时间',
  initialValue: 1
}, {
  key: 'dates',
  label: '',
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始日期", "结束日期"],
  dates: false,
  required: false,
  rules: [{
    required: false
  }]
}, {
  key: "button",
  button: true
}];
var tableColumns = [{
  title: 'ID',
  dataIndex: "id",
  key: "id",
  width: 140,
  fixed: "left"
}, {
  title: '用户昵称',
  dataIndex: "nickname",
  key: "nickname"
}, {
  title: '用户Username',
  dataIndex: "username",
  key: "username",
  scopedSlots: {
    customRender: "username"
  }
}, {
  title: 'UID',
  dataIndex: "uid",
  key: "uid"
}, {
  title: "认证类型",
  dataIndex: "certification",
  key: "certification",
  scopedSlots: {
    customRender: "certification"
  }
}, {
  title: '账号状态',
  dataIndex: "userstatusname",
  key: "userstatusname"
}, {
  title: '总计粉丝量',
  dataIndex: "allfollowernum",
  key: "allfollowernum"
}, {
  title: '总计作品量',
  dataIndex: "alldynamicnum",
  key: "alldynamicnum"
}, {
  title: '新增粉丝量',
  dataIndex: "addfollowernum",
  key: "addfollowernum",
  defaultSortOrder: "ascend",
  sorter: true,
  sortOrder: "ascend"
}, {
  title: '新增取关量',
  dataIndex: "delectfollowernum",
  key: "delectfollowernum",
  sorter: true
}, {
  title: '新增作品量',
  dataIndex: "adddynamicnum",
  key: "adddynamicnum",
  sorter: true
}];
export { searchKeys, tableColumns };